import React from 'react'
import "./imagehover.css"

const Imagehover = () => {
  return (
    <div className='mainImagediv'>
      {/* <div className='box1s'><span style={{fontSize:"1vw",fontWeight:"500"}}>Gagandeep Reehal</span><span style={{fontSize:"0.7vw"}}>Co-Founder CEO / CTO</span></div>
      <div className='box2s'><span style={{fontSize:"1vw",fontWeight:"500"}}>Gursimran Kalra</span><span style={{fontSize:"0.7vw"}}>Co-Founder COO</span></div>
      <div className='box3s'><span style={{fontSize:"1vw",fontWeight:"500"}}>Krishna Teja</span><span style={{fontSize:"0.7vw"}}>AVP,Strategy and BD</span></div>
      <div className='box4s'><span style={{fontSize:"1vw",fontWeight:"500"}}>Aayush Kumar</span><span style={{fontSize:"0.7vw"}}>Founder's Office - Strategy and Operations</span></div>
      <div className='box5s'><span style={{fontSize:"1vw",fontWeight:"500"}}>Abhishek Poddar</span><span style={{fontSize:"0.7vw"}}>Brand manager</span></div>
      <div className='box6s'><span style={{fontSize:"1vw",fontWeight:"500"}}>Akira Chinuva</span><span style={{fontSize:"0.7vw"}}>Brand manager</span></div>
      <div className='box7s'><span style={{fontSize:"1vw",fontWeight:"500"}}>Suraj Ghosh</span><span style={{fontSize:"0.7vw"}}>VP, Strategy and BD</span></div>
      <div className='box8s'><span style={{fontSize:"1vw",fontWeight:"500"}}>Ayushmaan Sharma</span><span style={{fontSize:"0.7vw"}}>Motion Graphic Desginer </span></div>
      <div className='box9s'><span style={{fontSize:"1vw",fontWeight:"500"}}>Kulalvoimoli L</span><span style={{fontSize:"0.7vw"}}>HR Analyst </span></div>
      <div className='box10s'><span style={{fontSize:"1vw",fontWeight:"500"}}>Sugun Segu</span><span style={{fontSize:"0.7vw"}}>Robotics Engineer </span></div>
      <div className='box11s'><span style={{fontSize:"1vw",fontWeight:"500"}}>Krishna Vamsi padullaparti</span><span style={{fontSize:"0.7vw"}}>3d Desinger  </span></div>
      <div className='box12s'><span style={{fontSize:"1vw",fontWeight:"500"}}>Piyush Thange</span><span style={{fontSize:"0.7vw"}}>System Software Engineer  </span></div>
      <div className='box13s'><span style={{fontSize:"1vw",fontWeight:"500"}}>Nehal </span><span style={{fontSize:"0.7vw"}}>Head of Planning and Prediction  </span></div>
      <div className='box14s'><span style={{fontSize:"1vw",fontWeight:"500"}}>Prakhar Agarwal  </span><span style={{fontSize:"0.7vw"}}>System Software Engineer  </span></div>
      <div className='box15s'><span style={{fontSize:"1vw",fontWeight:"500"}}>Abhishek Jadhav   </span><span style={{fontSize:"0.7vw"}}>Autombile Design Engineer  </span></div>
      <div className='box16s'><span style={{fontSize:"0.8vw",fontWeight:"500"}}>Sarvagya Malaviya  </span><span style={{fontSize:"0.6vw"}}>Research Engineer - Deep Learning  </span></div>
      <div className='box17s'><span style={{fontSize:"0.8vw",fontWeight:"500"}}>Sriharsha Bommana   </span><span style={{fontSize:"0.67vw"}}>Research Engineer - Artificial Intelligence  </span></div>
      <div className='box18s'><span style={{fontSize:"0.8vw",fontWeight:"500"}}>Sourav Kumar  </span><span style={{fontSize:"0.67vw"}}>Graphic Design Intern</span></div>
      <div className='box19s'><span style={{fontSize:"0.8vw",fontWeight:"500"}}>Vinay Sisodia  </span><span style={{fontSize:"0.67vw"}}>ReactJs Developer Intern</span></div>
      <div className='box20s'><span style={{fontSize:"0.8vw",fontWeight:"500"}}>Aditya Sivaraj  </span><span style={{fontSize:"0.67vw"}}>Robotics Engineer</span></div> */}
    </div>
  )
}

export default Imagehover
